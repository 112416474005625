import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { Badge } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  markAsSus,
  unMarkAsSus,
  deleteAccount,
} from "../service/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import SuspiciousConfirmationModal from "../components/common/SuspiciousModal";
import ConfirmationModal from "../components/modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const UserList = ({
  onToggleState,
  handleClearDevice,
  onEditUser,
  userType,
  setUserType,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [mobileSearchTerm, setMobileSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [markedUsers, setMarkedUsers] = useState([]);
  const [deviceIdSearchTerm, setDeviceIdSearchTerm] = useState("");
  const [switchLoading, setSwitchLoading] = useState(false);

  // handling reuseable modal component confirmationModal
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalAction, setModalAction] = useState(null);

  const handleToggleUserType = () => {
    // Toggle between two user types based on current state
    setSwitchLoading(true);
    setUserType(userType === "normal" ? "locked" : "normal");
  };

  useEffect(() => {
    setSwitchLoading(false);
  }, [users]);

  const onDeleteUser = (id, user) => {
    console.log(id);
    // dispatch(deleteAccount(id))
    setModalTitle("Delete User");
    setModalBody(
      <>
        Warning: this action cannot be undone and should only be used if
        requested by{" "}
        <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b>. Are
        you sure you want to permanently delete{" "}
        <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b>?
      </>
    );
    setModalAction(() => () => {
      dispatch(deleteAccount(id));
      setModalShow(false);
    });
    setModalShow(true);
  };

  const onMarkSus = (id) => {
    console.log(id);
    dispatch(markAsSus(id));
  };
  const onUnMarkSus = (id) => {
    console.log(id);
    dispatch(unMarkAsSus(id));
  };

  useEffect(() => {
    if (users.length > 0) {
      setLoading(false);
    }
  }, [users]);

  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const handleIdSearchChange = (e) => {
    setIdSearchTerm(e.target.value);
    // setCurrentPage(1);
  };

  const handleEmailSearchChange = (e) => {
    setEmailSearchTerm(e.target.value);
    // setCurrentPage(1);
  };

  const handleMobileSearchChange = (e) => {
    setMobileSearchTerm(e.target.value);
    // setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    // setCurrentPage(1);
  };
  const handleDeviceIdSearchChange = (e) => {
    setDeviceIdSearchTerm(e.target.value);
  };

  const sortedUsers = users
    .slice()
    .sort((a, b) => b.goldFlake_earned - a.goldFlake_earned);

  const filteredUsers = sortedUsers.filter((user) => {
    const idString = user.id.toString();
    const emailString = user.email ? user.email.toLocaleLowerCase() : "";
    const mobileString = user.mobile ? user.mobile.toLocaleLowerCase() : "";
    const deviceIdString = user.deviceId
      ? user.deviceId.toLocaleLowerCase()
      : "";

    const statusMatch =
      statusFilter === "all" ||
      (user.isActive && statusFilter === "active") ||
      (!user.isActive && statusFilter === "suspended");

    return (
      idString.startsWith(idSearchTerm.toLocaleLowerCase()) &&
      emailString.startsWith(emailSearchTerm.toLocaleLowerCase()) &&
      mobileString.startsWith(mobileSearchTerm.toLocaleLowerCase()) &&
      statusMatch &&
      deviceIdString.startsWith(deviceIdSearchTerm.toLowerCase())
    );
  });
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = (currentPage - 1) * itemsPerPage;

  //const renderedUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  //const lastPage = Math.ceil(filteredUsers.length / itemsPerPage);
  //const displayPageCount = 5; // Increased to show more page buttons

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    console.log("Selected Page:", selectedPage); // Add this log
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

  // const pageNumbers = [];
  // const startPage = Math.max(1, currentPage - Math.floor(displayPageCount / 2));
  // const endPage = Math.min(lastPage, startPage + displayPageCount - 1);

  const currentUsers = filteredUsers.slice(
    indexOfLastUser,
    indexOfLastUser + itemsPerPage
  );

  // for (let i = startPage; i <= endPage; i++) {
  //   pageNumbers.push(i);
  // }
  console.log("user", currentUsers[0]);
  const navigate = useNavigate();

  const navigateEMA =(id)=>{
    // console.log('NAVIGATING')
    navigate(`/playerEMA/${id}`)
  }

  const viewRating = (user) => {
    console.log("user", user.id);
    navigate(`/user/${user.id}/risk-factor`);
  };
  return (
    <div className="">
      <div className="">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <h3 style={{ marginTop: "14px" }}>Users</h3>
            <div
              className="pagination-parent"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
              />
              <Form.Check
                type="switch"
                id="userTypeSwitch"
                label={`Switch to ${
                  userType === "locked" ? "All Players" : "Opted In Players"
                }`}
                style={{ fontSize: "16px" }}
                checked={userType === "locked"}
                onChange={handleToggleUserType}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                type="text"
                placeholder="Search by ID"
                value={idSearchTerm}
                onChange={handleIdSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Email"
                value={emailSearchTerm}
                onChange={handleEmailSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Mobile"
                value={mobileSearchTerm}
                onChange={handleMobileSearchChange}
              />
              <Form.Control as="select" onChange={handleStatusFilterChange}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </Form.Control>
              <FormControl
                type="text"
                placeholder="Search by Device ID"
                value={deviceIdSearchTerm}
                onChange={handleDeviceIdSearchChange}
              />
            </div>

            {switchLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th style={{ minWidth: "100px" }}>Player ID</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Tokens Earned</th>
                    <th>Monthly Earning Cap</th>
                    <th>Monthly Remaining Cap</th>
                    <th>Boosted Monthly Cap</th>
                    <th>Player Risk Score</th>
                    <th style={{ minWidth: "100px" }}>UAF Score</th>
                    <th style={{ minWidth: "150px" }}>
                      Fraud Final Percentage
                    </th>

                    <th style={{ minWidth: "150px" }}>Risk Score Details</th>
                    <th>Device Id</th>
                    <th>EMA </th>

                    <th>Warned</th>
                    <th>Disabled</th>
                    <th>Status</th>
                    <th style={{ minWidth: "150px" }}>Action</th>
                    <th>Doubt</th>
                    <th style={{ minWidth: "150px" }}>Good Bye</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.email || "N/A"}</td>
                      <td>{user.mobile || "N/A"}</td>
                      <td>{user.goldFlake_earned}</td>
                      <td>{user.monthly_earning_cap}</td>
                      <td>{user.monthly_remaining_cap}</td>
                      <td>{user.boosted_monthly_cap}</td>
                      <td>{user.playerRiskScore}</td>
                      <td>{user.UAFScore}</td>
                      <td>{user?.fraudFinalPercent}</td>
                      <td>
                        <Button
                          className="btn btn-primary"
                          onClick={() => viewRating(user)}
                        >
                          View Details
                        </Button>
                      </td>
                      <td>{user.deviceId || "-"}</td>
                      <td>
                        <Button
                          style={{ width: "120px" }}
                          variant="success"
                          onClick={() => navigateEMA(user.id)}
                        >
                          SHOW EMA
                        </Button>
                      </td>

                      <td>
                        <Badge bg={user.warning ? "danger" : "success"}>
                          {user.warning ? "true" : "false"}
                        </Badge>
                      </td>
                      <td>
                        <Badge bg={user.accountDisable ? "danger" : "success"}>
                          {user.accountDisable ? "true" : "false"}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          variant={user.isActive ? "danger" : "success"}
                          // onClick={() => onToggleState(user)}
                          onClick={() => {
                            setModalTitle(
                              user.isActive ? "Suspend User" : "Activate User"
                            );
                            setModalBody(
                              user.isActive ? (
                                <>
                                  Are you sure you want to suspend{" "}
                                  <b>
                                    {user.email
                                      ? user.email
                                      : user.mobile
                                      ? user.mobile
                                      : "NA"}
                                  </b>
                                  ?
                                </>
                              ) : (
                                <>
                                  Are you sure you want to activate{" "}
                                  <b>
                                    {user.email
                                      ? user.email
                                      : user.mobile
                                      ? user.mobile
                                      : "NA"}
                                  </b>
                                  ?
                                </>
                              )
                            );
                            setModalAction(() => () => {
                              onToggleState(user);
                              setModalShow(false);
                            });
                            setModalShow(true);
                          }}
                        >
                          {user.isActive ? "Suspend" : "Activate"}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant={"secondary"}
                          // onClick={() => handleClearDevice(user)}
                          onClick={() => {
                            setModalTitle("Clear Device");
                            setModalBody(
                              <>
                                Warning: this action cannot be undone and should
                                only be used if requested by{" "}
                                <b>
                                  {user.email
                                    ? user.email
                                    : user.mobile
                                    ? user.mobile
                                    : "NA"}
                                </b>
                                . Are you sure you want to permanently delete{" "}
                                <b>
                                  {user.email
                                    ? user.email
                                    : user.mobile
                                    ? user.mobile
                                    : "NA"}
                                </b>
                                ?
                              </>
                            );
                            setModalAction(() => () => {
                              handleClearDevice(user);
                              setModalShow(false);
                            });
                            setModalShow(true);
                          }}
                        >
                          Clear Device
                        </Button>
                      </td>
                      <td>
                        <SuspiciousConfirmationModal
                          user={user}
                          onMark={onMarkSus}
                          onUnmark={onUnMarkSus}
                          markedUsers={markedUsers}
                          setMarkedUsers={setMarkedUsers}
                        />
                      </td>
                      <td>
                        <Button
                          variant={user.accountDeleted ? "success" : "danger"}
                          onClick={() => onDeleteUser(user.id, user)}
                          disabled={user.accountDeleted}
                        >
                          {user.accountDeleted
                            ? "Not Available"
                            : "Delete Account"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={modalTitle}
        bodyContent={modalBody}
        actionButtonText="Confirm"
        onAction={modalAction}
      />
    </div>
  );
};

export default UserList;
