import http from "../../utils/Api";

const getDeviceInformation =
  (page, limit = 10, setPageCount, gyroscope, accelerometer) =>
  async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      let url = `/api/admin/getAllPlayersDeviceInformation?page=${page}&limit=${limit}`;

      // Append gyroscope filter if it's a boolean
      if (gyroscope === 'true' || gyroscope === "false") {
        url += `&gyrpscope=${gyroscope}`;
      }

      // Append accelerometer filter if it's a boolean
      if (accelerometer === 'true' || accelerometer === "false") {
        url += `&accelerometer=${accelerometer}`;
      }
      const res = await http.get(
        url,
        http.generateConfig(accessToken) // Ensure accessToken is available in your context
      );
      setPageCount(res.totalPages);
      if (res) {
        dispatch({
          type: "deviceInformation",
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export { getDeviceInformation };
